import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import { without, filter } from "lodash";
import Select from "react-select";
import "../index.css";
//Librerias de la tabla
import { lighten } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import { CircularProgress, TablePagination } from "@material-ui/core";

import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { param } from "jquery";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import { encryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import XLSX from "xlsx";
//Finaliza las librerias de la tabla
import Graph from "react-vis-network-graph"; //Libreria para el grafo
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
//procesos header
const headCellsProcesos = [
  {
    id: "idsubproceso",
    numeric: false,
    disablePadding: true,
    label: "Id Subproceso",
  },
  {
    id: "macroproceso",
    numeric: false,
    disablePadding: false,
    label: "Macroproceso",
  },
  { id: "proceso", numeric: false, disablePadding: false, label: "Proceso" },
  {
    id: "subproceso",
    numeric: false,
    disablePadding: false,
    label: "Subproceso",
  },
  { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
];
//activos header
const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },
  { id: "ip", numeric: false, disablePadding: false, label: "Dirección IP" },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción",
  },
  {
    id: "ubicacion_logica",
    numeric: false,
    disablePadding: false,
    label: "Ubicación lógica",
  },
  {
    id: "relevancia",
    numeric: false,
    disablePadding: false,
    label: "Relevancia",
  },
  {
    id: "tipo_ambiente",
    numeric: false,
    disablePadding: false,
    label: "Tipo de ambiente",
  },
];
//////////////////////Tabla dentro del Modal
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHeadProcesos(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCellsProcesos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHeadProcesos.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
//////////////////////Fin Tabla dentro del Modal
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Evaluaciones
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list" size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));
//////////////////////Fin Tabla dentro del Modal

//Empieza tabla de activos
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const stylesSelect = {
  control: (base) => ({
    ...base,
    fontFamily: "Roboto",
  }),
  menu: (base) => ({
    ...base,
    fontFamily: "CIBFont Sans Regular",
  }),

  option: (provided, state) => ({
    ...provided,
    //color: state.isFocused ? 'white' : '#495057',
  }),
};

//Finaliza tabla de activos

const EditarComponente = (props) => {
  const ismodule = props.location.state ? props.location.state.value : 1; //Acá hay que cambiarlo para cuando es la creación de un módulo desde una filial
  const classes = useStyles();
  const [modalShow, setModalShow] = React.useState(false);
  const [activo, setActivo] = React.useState([]);
  const [datagrafo, setDataGrafo] = React.useState([]);
  const [evc, setEvc] = React.useState([]);
  const [companiaDat, setCompaniaDat] = React.useState([]);
  const [usuariosDatNeg, setUsuariosDatNeg] = React.useState([]);
  const [usuariosDatTI, setUsuariosDatTI] = React.useState([]);
  const [tipoComp2, setTipoComp2] = React.useState([]);
  const [modalInfo, setModalInfo] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [DataCanal, setDataCanal] = React.useState([]);
  const [DataDespliegue, setDataDespliegue] = React.useState([]);
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  /////////////////////////////////////
  const tipoComp = llenarTipoComp(tipoComp2);

  const [state, setState] = useState("Activo");
  const [idState, setIdState] = useState(true);
  const [vRequired, setVRequired] = useState(true);
  const [ast, setAst] = useState("*");
  //////////////////////Constantes datos selects
  const [selectedValueCompania, setSelectedValueCompania] = useState(null);
  const [selectedValueResponsableTI, setSelectedValueResponsableTI] =
    useState(null);
  //const [selectedCompUsadadef, setSelectedCompUsadadef] = useState([]);
  const [selectedCompUsada, setSelectedCompUsada] = useState([]);
  const [selecteddespliegue, setSelecteddespliegue] = useState([]);
  const [selectedValueResponsableNeg, setSelectedValueResponsableNeg] =
    useState(null);
  const [selectedCanal, setSelectedCanal] = useState(null);
  const [tipoCanal, setTipoCanal] = useState([]);
  ///////////////////////////////
  const [rowsc, setRowsC] = React.useState([]);
  const [rowsCompInit, setRowsCompInit] = React.useState([]);
  const [rowsP, setRowsP] = React.useState([]);
  const [rowsProcInit, setRowsProcInit] = React.useState([]);
  const [rowsM, setRowsM] = React.useState([]);
  const [rowsModInit, setRowsModInit] = React.useState([]);
  const [dataComponentePrincipalId, setDataComponentePrincipalId] = useState("");
  const [dataComponentePrincipalNombre, setDataComponentePrincipalNombre] = useState("");
  const [procesosSelected, setProcesosSelected] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  //////////////////////Constante Datos Tabla Modal
  const [modalCompo, setModalCompo] = useState([]);
  const [modalShowProcesos, setModalShowProcesos] = React.useState(false);
  const [datContenidos, setDatContenidos] = React.useState([]);
  const [dataNUB, setDataNUB] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [codigoActivo, setCodigoActivo] = React.useState("");
  const [oldIdTipoActivo, setOldIdTipoActivo] = useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  useEffect(() => {
    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "allcomponents/" +
        localStorage.getItem("idcompania") +
        "/0/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datComponentes = await result.json();
        setModalCompo(datComponentes);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    const obtenerProceso = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "general/procesosxCompania/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datProcesos = await result.json();
        setModalInfo(datProcesos);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    const obtenerTipoDespliegue = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Componentes/Tipo_Despliegue/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datTipoDespliegue = await result.json();
      setDataDespliegue(datTipoDespliegue);
    };
    const obtenerTipoCanal = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Componentes/Tipo_Canal/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datTipoCanal = await result.json();
      setDataCanal(datTipoCanal);
    };
    const obtenerActivo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "activoInformacion/" +
        localStorage.getItem("idactivo") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encrypteddatActivo = await result.json();
      const decrypteddatActivo = DecryptData(encrypteddatActivo);
      let datActivo = JSON.parse(decrypteddatActivo);
      let temp = [];
      let temp2 = [];
      let temp3 = [];
      let tempRowC = [];
      let tempRowP = [];
      let tempRowM = [];
      if (datActivo.ComponentesInfraestructura !== null) {
        for (let i = 0; i < datActivo.ComponentesInfraestructura.length; i++) {
          const element = datActivo.ComponentesInfraestructura[i];
          if (element.estadoasociacion == true) {
            tempRowC.push(element);
            temp.push(element.idactivo);
          }
        }
      }
      if (datActivo.ProcesosAsociados) {
        for (let i = 0; i < datActivo.ProcesosAsociados.length; i++) {
          const element = datActivo.ProcesosAsociados[i];
          if (element.estadoasociacion == true) {
            temp2.push(element.idsubproceso);
            tempRowP.push(element);
          }
        }
      }

      if (datActivo.ComponentesModulos !== null) {
        for (let i = 0; i < datActivo.ComponentesModulos.length; i++) {
          const element = datActivo.ComponentesModulos[i];
          if (element.estadoasociacion == true) {
            tempRowM.push(element);
            temp3.push(element.idactivo);
          }
        }
      }
      if (datActivo.ComponentePrincipal !== null) {
        setDataComponentePrincipalId(datActivo.ComponentePrincipal[0].idcomponenteppal);
        setDataComponentePrincipalNombre(datActivo.ComponentePrincipal[0].componenteppal);
      }

      if (datActivo.datos_en_el_activo !== null) {
        setDatContenidos(datActivo.datos_en_el_activo.split(","));
      }

      if (datActivo != null && datActivo.UsuarioResponsableNegocio !== null) {
        setSelectedValueResponsableNeg({
          value: datActivo.idposicionresponsablenegocio,
          label: datActivo.UsuarioResponsableNegocio,
        });
      }

      if (datActivo != null && datActivo.UsuarioResponsableTI !== null) {
        setSelectedValueResponsableTI({
          value: datActivo.idposicionresponsableti,
          label: datActivo.UsuarioResponsableTI,
        });
      }
      if (datActivo != null && datActivo.tipo_canal !== null) {
        setSelectedCanal({
          value: datActivo.tipo_canal,
          label: datActivo.tipo_canal,
        });
        setTipoCanal(datActivo.tipo_canal);
      }
      if (datActivo != null && datActivo.tipo_activo !== null) {
        if (
          datActivo.tipo_activo == "Aplicativos" ||
          datActivo.tipo_activo == "Servidores" ||
          datActivo.tipo_activo == "Apis"
        ) {
          setCodigoActivo({
            value: datActivo.idtipo_activo,
            label: datActivo.tipo_activo,
          });
        } else {
          setCodigoActivo({});
        }
      }
      if (datActivo != null && datActivo.tipo_despliegue !== null) {
        const companiasUsanArrayD = datActivo.tipo_despliegue.split(",");
        const companiasUsanObjArrayD = [];
        companiasUsanArrayD.forEach((itemD) => {
          companiasUsanObjArrayD.push({
            value: itemD,
            label: itemD,
          });
        });
        setSelecteddespliegue(companiasUsanObjArrayD);
      }
      if (datActivo != null && datActivo.companias_usan !== null) {
        const companiasUsanArray = datActivo.companias_usan.split(",");
        const companiasUsanObjArray = [];
        companiasUsanArray.forEach((item) => {
          companiasUsanObjArray.push({
            value: item,
            label: item,
          });
        });
        setSelectedCompUsada(companiasUsanObjArray);
      }
      if (datActivo.old_idtipo_activo != null && datActivo.old_idtipo_activo == "Nuevo") {
        setOldIdTipoActivo(true);
      }
      const data = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      let datCompania = await data.json();
      let nombrecompania = datCompania
        .filter(({ idcompania }) => idcompania == datActivo.idcompania)
        .map(({ compania }) => compania)
        .pop();

      if (datActivo != null && datActivo.idcompania !== null) {
        setSelectedValueCompania({
          value: datActivo.idcompania,
          label: nombrecompania,
        });
      }
      setComponentesSelected(temp);
      setProcesosSelected(temp2);
      if (datActivo.componente_principal == true) {
        setVRequired(false);
      } else {
        setVRequired(true);
      }

      try {
        document.getElementById("Nube").checked = datActivo.nube;
        document.getElementById("BIA").checked = datActivo.bia;
        document.getElementById("SOX").checked = datActivo.sox;
      } catch { }
      setRowsC(tempRowC);
      setRowsCompInit(datActivo.ComponentesInfraestructura);
      setRowsP(tempRowP);
      setRowsProcInit(datActivo.ProcesosAsociados);
      setRowsM(tempRowM);
      setRowsModInit(datActivo.ComponentesModulos);
      setActivo(datActivo);
      setIdState(datActivo.estado);
      if (datActivo.estado == true) {
        setState("Activo");
      } else {
        setState("Inactivo");
      }
      if (datActivo.componente_principal) {
        setVRequired(false);
        setAst("");
      } else {
        setVRequired(true);
        setAst("*");
      }
      if (datActivo.alcance_corporativo) {
        setAlcance(datActivo.alcance_corporativo);
      }
    };

    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setCompaniaDat(datCompania);
    };
    const obtenerEVC = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "general/evc/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datEVC = await result.json();
      setEvc(datEVC);
    };
    const obtenerUsuariosNeg = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrolneg/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();

      let usuariosNeg = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );

      setUsuariosDatNeg(usuariosNeg);
    };

    const obtenerUsuariosTI = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "usuariosrolti/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datUsuarios = await result.json();
      let usuariosTI = datUsuarios.map(
        ({ idposicion: value, nombre: label }) => ({
          value,
          label,
        })
      );

      setUsuariosDatTI(usuariosTI);
    };
    const obtenerTipoComp = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/tipoactivo/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setTipoComp2(data);
    };
    const obtenergrafo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "maestro/grafo/" +
        localStorage.getItem("idactivo"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datGrafo = await result.json();
        // Verificar la estructura esperada
        const nodes = datGrafo[0]?.nodes || [];
        const edges = datGrafo[1]?.edges || [];
        const graf = { nodes, edges };
        setDataGrafo(graf);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    obtenerComponentes();
    //fetchdata_NUB();
    obtenerProceso();
    obtenerActivo();
    obtenerTipoComp();
    obtenerUsuariosTI();
    obtenerUsuariosNeg();
    obtenerCompañias();
    obtenerEVC();
    obtenerTipoDespliegue();
    obtenerTipoCanal();
    obtenergrafo();
  }, []);
  /////// Añadir tags para los componentes asociados
  const removeTag = (i) => {
    const newTags = [...datContenidos];
    newTags.splice(i, 1);
    setDatContenidos(newTags);
  };

  const convertToDot = (data) => {
    if (data.nodes.length > 0) {
      let dot = "digraph G {\n";
      data.nodes.forEach((node) => {
        if (node.id == "app") {
          dot += `${node.id} [label="${node.label}"];\n`;
        }
        if (node.id.startsWith("componentes")) {
          dot += `${node.id} [shape=record,label="{${node.label}`;
          node.ips.forEach((ip) => {
            dot += `|{<here>${ip.direccion}}`;
          });
          dot += `}"];\n`;
        }
        if (node.id.startsWith("modulo")) {
          dot += `${node.id} [label="${node.label}"];\n`;
          if (node.ips.length > 0) {
            dot += `componentes${node.id} [shape=record,label="{Componentes modulo`;
            node.ips.forEach((ip) => {
              dot += `|{<here>${ip.direccion}}`;
            });
            dot += `}"];\n`;
          }
        }
      });

      data.edges.forEach((edge) => {
        dot += `  ${edge.from} -> ${edge.to};\n`;
      });
      dot += "}";
      return dot;
    }
  };

  const DynamicGraph = ({ data }) => {
    const datosComoString = JSON.stringify(data);
    const datosParseados = JSON.parse(datosComoString);
    const dot = convertToDot(data);
    const drawNode = (ctx, { x, y, label, ips }) => {
      const baseWidth = 200;
      const baseHeight = 50;
      const lineHeight = 20;
      const padding = 10;

      const width = baseWidth;
      const height = baseHeight + (ips ? ips.length * lineHeight : 0) + padding;

      // Dibuja el fondo del nodo
      ctx.fillStyle = '#ffffff';
      ctx.strokeStyle = '#000000';
      ctx.beginPath();
      ctx.rect(x - width / 2, y - height / 2, width, height);
      ctx.fill();
      ctx.stroke();

      // Dibuja el título
      ctx.font = 'bold 14px Arial';
      ctx.fillStyle = '#000000';
      ctx.textAlign = 'center';
      ctx.fillText(label, x, y - height / 2 + 25);

      // Dibuja la tabla de IPs si existen
      if (ips && ips.length > 0) {
        ctx.font = '12px Arial';
        ctx.textAlign = 'left';
        ctx.fillText('IPs:', x - width / 2 + padding, y - height / 2 + 45);
        ips.forEach((ip, index) => {
          ctx.fillText(ip.direccion, x - width / 2 + padding, y - height / 2 + 65 + index * lineHeight);
        });
      }
    };

    const graph = {
      nodes: data.nodes.map(node => ({
        ...node,
        shape: 'custom',
        level: node.id === 'app' ? 0 : 1,  // Asigna nivel 0 al nodo 'app', 1 a los demás
        ctxRenderer: ({ ctx, x, y, selected, hover }) => {
          drawNode(ctx, { ...node, x, y });
          return { nodeAlwaysOnTop: false };
        }
      })),
      edges: data.edges
    };
    const options = {
      interaction: {
        navigationButtons: true
      },
      layout: {
        hierarchical: {
          enabled: true,
          direction: 'UD',  // Up to Down
          sortMethod: 'directed',
          nodeSpacing: 400,
          levelSeparation: 300
        }
      },
      edges: {
        color: "#000000",
        arrows: {
          to: {
            enabled: false,
            scaleFactor: 1
          }
        }
      },
      height: "500px",
      width: "100%",
      physics: {
        enabled: false
      }
    };
    return (
      <Graph
        graph={graph}
        options={options}
      />
    );
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      e.preventDefault();
      setValidated(false);
      if (
        datContenidos.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setDatContenidos([...datContenidos, val]);
      let inputTag = document.getElementById("datosSeparados");
      inputTag.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(datContenidos.length - 1);
    }
  };

  const ubicacion_logica = [
    { id: "1", nombre: "Expuesto a internet" },
    { id: "2", nombre: "Red Confianza" },
    { id: "3", nombre: "Red Interna" },
    { id: "4", nombre: "Segmento controlado" },
  ];
  const relevancia = [
    { id: "1", nombre: "Componente Soporte" },
    {
      id: "2",
      nombre: "Infraestructura crítica transversal/Punto único de falla",
    },
    { id: "3", nombre: "Infraestructura crítica no transversal" },
  ];
  const tipo_ambiente = [
    { id: "1", nombre: "Producción" },
    { id: "2", nombre: "Certificación" },
  ];
  const ExportToExcel = ({ apiData }) => {
    const downloadExcel = (data) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "informe.xlsx");
    };

    return (
      <Button
        className="botonPositivo"
        style={{ minWidth: "250px", float: "right" }}
        onClick={(e) => downloadExcel(apiData)}
      >
        Descargar Informe
      </Button>
    );
  };

  function ModalCarga(props) {
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [archivo, setArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [informeExcel, setInformeExcel] = React.useState([]);
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    let tempArchivos = [];
    let temp = [];

    const subirArchivo = (e) => {
      setEstadoPost(0);
      setArchivo([]);
      //setNames([]);
      setStateArchivo([]);
      setNames([]);
      //setNamesN([]);

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
      // setLoading(false)
    };

    /*     const fetchdataPost = async () => {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "activoInfoMasivo/" +
              localStorage.getItem("idcompania") +
              "/",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let data = await result.json();
          setActivo(data);
        }; */

    const postArchivo = async (event, file) => {
      setLoading(true);
      if (file.length) {
        setEstadoPost(0);
        event.preventDefault();
        const form = event.currentTarget;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheetName = workbook.SheetNames[0];
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          procesarDatos(names[0], XL_row_object);
        };
      } else {
        event.preventDefault();
        setLoading(false);
        setEstadoPost(3);
      }
      // setLoading(false)
    };

    const procesarDatos = async (archivosKEY, info) => {
      let today = new Date().toISOString();
      let idUsuario = localStorage.getItem("idusuario");
      const infoConNuevosDatos = info.map((elemento) => ({
        ...elemento,
        datos_en_el_activo: labelDatContenido,
        idposicionresponsablenegocio:
          selectedValueResponsableNeg != null
            ? parseInt(Object.values(selectedValueResponsableNeg)[0])
            : null,
        idposicionresponsableti: parseInt(
          Object.values(selectedValueResponsableTI)[0]
        ),
        idtipo_activo: codigoActivo ? codigoActivo.value : null,
        companias_usan: labelcompusada,
        tipo_canal: selectedCanal.label,
        tipo_despliegue: labelvaluedesp,
        fechacreacion: today,
        fechamodificacion: today,
        idusuariocreacion: idUsuario,
        idusuariomodificacion: idUsuario,
        componente_principal: 0,
      }));

      var data = {
        key: archivosKEY,
        message: infoConNuevosDatos,
      };

      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
        "activoInfoMasivo/" +
        localStorage.getItem("idcompania") +
        "/",
        {
          method: "POST",
          //responseType: "blob",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            if (response.status >= 200 && response.status < 300) {
              //setEstadoPost(2);
              setLoading(false);
              //fetchdataPost();
              setRowsC([...rowsc, ...data.data]);
              alert(data.message);
              setShowModal(false);
              setModalCompo([...modalCompo, ...data.data]);
              //props.onHide();
            } else if (response.status >= 500) {
              setEstadoPost(5);
              //props.onHide();
              setLoading(false);
            } else if (response.status >= 400 && response.status < 500) {
              alert(data.message);
              setShowModal(false);
              //setEstadoPost(4);
              setLoading(false);
            }
          })
        )
        .catch(function (err) { });
      //setShowModal(true);
    };

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form
        /*           onSubmit={(e) => {
                    postArchivo(e, archivo);
                  }} */
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Cargue Masivo de Componentes
              <br></br>
              <a
                href="https://bancolombia.sharepoint.com.mcas.ms/teams/CdulaRiesgoTI-Ciber/_layouts/15/download.aspx?UniqueId=ac10f560%2Db69d%2D4bc2%2Da755%2D8240645a720c&McasTsid=20892"
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar plantilla
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col>
                <input
                  type="file"
                  name="files"
                  accept=".xlsx,.csv"
                  multiple
                  onChange={(e) => subirArchivo(e.target.files)}
                ></input>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <ul className="list-group">
                  <li className="list-group-item">
                    <Row>
                      <Col sm={4}>
                        <h5>Nombre del archivo</h5>
                      </Col>
                      <Col sm={4}>
                        <h5>Nombre en el servidor</h5>
                      </Col>
                      <Col sm={1}>
                        <h5>Estado</h5>
                      </Col>
                    </Row>
                  </li>
                  {names.map((item, index) => (
                    <li className="list-group-item" key={index}>
                      <Row>
                        <Col sm={4}>{item}</Col>
                        <Col sm={4}>{names[index]}</Col>
                        <Col sm={1}>{stateArchivo[index]}</Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <Button
                //type='submit'
                className="botonPositivo"
                style={{ minWidth: "20%" }}
                onClick={(e) => {
                  postArchivo(e, archivo);
                }}
              >
                Cargar
              </Button>
            )}
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  function llenarTipoComp(data) {
    let tipo = [];
    for (const clave in data) {
      if (data[clave]["codigogeneral"] == "COM" && data[clave]["estado"] == 1) {
        tipo.push(data[clave]);
      }
    }
    return tipo;
  }

  const handleChangeState = (event) => {
    if (state == "Activo") {
      setState("Inactivo");
      setIdState(false);
    } else {
      setState("Activo");
      setIdState(true);
    }
  };

  ////////////////////////Crea el componente Select
  const MySelect = (props) => (
    <Select
      {...props}
      className="texto"
      options={props.options}
      placeholder={props.placeholder}
    />
  );

  const handleChange = (e) => {
    setSelectedValueCompania({ value: e.value, label: e.label });
  };
  const handleChangeCompUsada = (selectedOptions) => {
    setSelectedCompUsada(selectedOptions);
    let temp_paises = [];
    selectedOptions.map((dat) => {
      temp_paises.push(dat.pais);
    });
    let temp_paises_unicos = [...new Set(temp_paises)];
    if (temp_paises_unicos.includes("General")) {
      setAlcance(1);
    } else if (temp_paises_unicos.length > 1) {
      setAlcance(1);
    } else {
      setAlcance(0);
    }
  };

  const handleChangeTI = (e) => {
    setSelectedValueResponsableTI({ value: e.value, label: e.label });
  };
  const handleChangedesp = (selectedOptionsD) => {
    setSelecteddespliegue(selectedOptionsD);
  };

  const handleChangeNeg = (e) => {
    setSelectedValueResponsableNeg({ value: e.value, label: e.label });
  };
  const handleChangeCompT = (e) => {
    setCodigoActivo({ value: e.value, label: e.label });
  };
  const handleChangeCanal = (e) => {
    setSelectedCanal({ value: e.value, label: e.label });
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let rowscT;
      let rowspT;
      let actualizarT = [];
      let actualizarF = [];
      let nuevos = rowsc;
      if (rowsProcInit != null) {
        rowspT = rowsProcInit.filter((dato) => dato.estadoasociacion == true);
      }
      if (rowsCompInit != null) {
        rowscT = rowsCompInit.filter((dato) => dato.estadoasociacion == true);
      }

      //-//-// Obtener los nuevos seleccionados
      if (rowsCompInit !== null && nuevos !== null) {
        rowsCompInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idactivo != row.idactivo);
        });
      }
      //-//-// Obtener los que se deben Actualizar a False
      if (rowscT != null && rowsc != null) {
        actualizarF = rowscT;
        rowsc.map((row) => {
          actualizarF = filter(actualizarF, (o) => o.idactivo != row.idactivo);
        });
      }
      //-//-// Obtener los que se deben Actualizar a True
      if (rowsCompInit != null) {
        rowsCompInit.map((row) => {
          rowsc.map((act) => {
            if (row.estadoasociacion == false && row.idactivo == act.idactivo) {
              actualizarT.push(row);
            }
          });
        });
      }

      let componentesInfraestructuraTemp = [];
      if (actualizarT !== null) {
        actualizarT.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: obj.idcomponentedelcomponente,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }
      if (actualizarF !== null) {
        actualizarF.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: obj.idcomponentedelcomponente,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          componentesInfraestructuraTemp.push({
            idcomponentedelcomponente: 0,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            idactivocomponente: obj.idactivo,
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
            disp_numerico1: null,
            disp_numerico2: null,
            disp_varchar1: null,
            disp_varchar2: null,
          });
        });
      }

      //-//-// Creación para los procesos asociados
      let procesosAsociadosTemp = [];
      nuevos = rowsP;
      actualizarT = [];
      actualizarF = [];
      //-//-// Nuevos procesos
      if (rowsProcInit !== null && nuevos !== null) {
        rowsProcInit.map((row) => {
          nuevos = filter(nuevos, (o) => o.idsubproceso != row.idsubproceso);
        });
      }

      //-//-// Obtener los que se deben Actualizar a False
      if (rowspT !== null) {
        actualizarF = rowspT;
        rowsP.map((row) => {
          actualizarF = filter(
            actualizarF,
            (o) => o.idsubproceso != row.idsubproceso
          );
        });
      }

      //-//-// Obtener los que se deben Actualizar a True
      if (rowsProcInit !== null) {
        rowsProcInit.map((row) => {
          rowsP.map((act) => {
            if (
              (row.estadoasociacion =
                false && row.idsubproceso == act.idsubproceso)
            ) {
              actualizarT.push(row);
            }
          });
        });
      }

      if (actualizarT !== null && actualizarT !== undefined) {
        actualizarT.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: true,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (actualizarF !== null && actualizarF !== undefined) {
        actualizarF.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: obj.idprocesoasociado,
            idsubproceso: obj.idsubproceso,
            idactivo: obj.idactivo,
            fechacreacion: obj.fechacreacion,
            estadoasociacion: false,
            idusuariocreacion: obj.idusuariocreacion,
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (nuevos !== null) {
        nuevos.map((obj) => {
          procesosAsociadosTemp.push({
            idprocesoasociado: 0,
            idsubproceso: obj.idsubproceso,
            idactivo: parseInt(document.getElementById("IDcomponente").value),
            fechacreacion: today.toISOString(),
            estadoasociacion: true,
            idusuariocreacion: localStorage.getItem("idusuario"),
            fechamodificacion: today.toISOString(),
            idusuariomodificacion: localStorage.getItem("idusuario"),
          });
        });
      }
      if (
        labelvaluedesp == null ||
        labelvaluedesp == undefined ||
        labelvaluedesp == "" ||
        labelcompusada == null ||
        labelcompusada == undefined ||
        labelcompusada == "" ||
        selectedCanal == null ||
        selectedCanal == undefined ||
        selectedCanal == ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Validación para el campo idposicionresponsableti, no puede ser null
      if (
        Object.values(selectedValueResponsableTI)[0] == null ||
        Object.values(selectedValueResponsableTI)[0] == undefined ||
        Object.values(selectedValueResponsableTI)[0] == ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Validación para el campo idtipo_activo, no puede ser null
      if (
        codigoActivo == null ||
        codigoActivo == undefined ||
        codigoActivo == ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      var data = {
        idactivo: parseInt(document.getElementById("IDcomponente").value),
        idcompania: localStorage.getItem("idcompania"),
        tipo_despliegue: labelvaluedesp,
        tipo_canal: selectedCanal.label,
        companias_usan: labelcompusada,
        idtipo_activo: parseInt(codigoActivo.value),
        nombre: document.getElementById("NombreComponente").value,
        elemento_configuracion: document.getElementById("CodigoApp").value,
        idposicionresponsableti: parseInt(
          Object.values(selectedValueResponsableTI)[0]
        ),
        idposicionresponsablenegocio:
          selectedValueResponsableNeg != null
            ? parseInt(Object.values(selectedValueResponsableNeg)[0])
            : null,
        descripcion: document.getElementById("Descripcion").value,
        datos_en_el_activo: labelDatContenido,
        estado: idState,
        componente_principal: ismodule,
        //bia: document.getElementById("BIA").value ? true : false,
        //sox: document.getElementById("SOX").value ? true : false,
        idevc:
          document.getElementById("EVC") == null ||
            document.getElementById("EVC") == undefined
            ? null
            : document.getElementById("EVC").value,
        /* ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        //nube:"",
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "", */
        fechacreacion: activo.fechacreacion,
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentesAsociados: "",
        ProveedoresAsociados: "",
        ProductoServiciosAsociados: "",
        ProcesosAsociados: procesosAsociadosTemp,
        ComponentesInfraestructura: componentesInfraestructuraTemp,
        alcance_corporativo: alcance,
      };
      const objectToSend = JSON.stringify(data);

      fetch(
        process.env.REACT_APP_API_URL +
        "activoInformacion/" +
        document.getElementById("IDcomponente").value +
        "/",
        {
          method: "PUT",
          body: objectToSend,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              if (props.location.state === undefined) {
                localStorage.setItem("idactivo", response.idactivo);
                history.push("/EditarComponente");
              } else {
                if (props.location.state === "DET") {
                  localStorage.setItem(
                    "idevaluacion",
                    props.location.id_evaluacion
                  );
                  history.push("/Descripción de componentes");
                } else {
                  localStorage.setItem("idactivo", response.idactivo);
                  history.push("/EditarComponente");
                }
              }
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) { });
    }
    setValidated(true);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Procesos
  function MyVerticallyCenteredModalProcesos(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [procesosTemp, setProcesosTemp] = React.useState(props.procesos);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalInfo.map((n) => n.idsubproceso);
        setProcesosTemp(newSelecteds);
        return;
      }
      setProcesosTemp([]);
    };
    const handleClick = (event, idsubproceso) => {
      const selectedIndex = procesosTemp.indexOf(idsubproceso);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(procesosTemp, idsubproceso);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(procesosTemp.slice(1));
      } else if (selectedIndex === procesosTemp.length - 1) {
        newSelected = newSelected.concat(procesosTemp.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          procesosTemp.slice(0, selectedIndex),
          procesosTemp.slice(selectedIndex + 1)
        );
      }
      setProcesosTemp(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (idsubproceso) =>
      procesosTemp.indexOf(idsubproceso) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalInfo.length - page * rowsPerPage);

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir Procesos asociados al componente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  {
                    <Link to="NuevoComponente">
                      <Button
                        className="botonPositivo"
                        style={{ marginTop: "1%", width: "100%" }}
                      >
                        {" "}
                        Nuevo{" "}
                      </Button>
                    </Link>
                  }
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(procesosTemp, "procesos")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHeadProcesos
                    classes={classes}
                    numSelected={procesosTemp.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalInfo.length}
                  />
                  <TableBody>
                    {stableSort(modalInfo, getComparator(order, orderBy))
                      .filter((row) =>
                        row.idsubproceso
                          .toLowerCase()
                          .includes(queryText.toLowerCase())
                      )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idsubproceso);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, row.idsubproceso)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.idsubproceso}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.idsubproceso}
                            </TableCell>
                            <TableCell align="left">
                              {row.macroproceso}
                            </TableCell>
                            <TableCell align="left">{row.proceso}</TableCell>
                            <TableCell align="left">{row.subproceso}</TableCell>
                            <StyledTableCell align="center">
                              <Checkbox
                                inputProps={{ "aria-label": "Checkbox A" }}
                                checked={row.estado}
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={modalInfo.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //Componentes
  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [activo, setActivo] = React.useState([]);
    const [ButtonEdit, SetButtonEdit] = React.useState(false);
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);

        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };

    function add_eval() {
      localStorage.setItem("idactivo", 0);
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, modalCompo.length - page * rowsPerPage);

    const comData = stableSort(modalCompo, getComparator(order, orderBy))
      .filter((row) =>
        row.idactivo.toString().includes(queryText.toLowerCase())
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const final_data_ifrastructure = comData;

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir componentes de infraestructura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                {/* <Col sm={4} xs={6}>
                  <Link to="NuevoComponente">
                    <Button
                      className="botonPositivo"
                      style={{ marginTop: "1%", width: "100%" }}
                      onClick={() => add_eval()}
                    >
                      {" "}
                      Nuevo{" "}
                    </Button>
                  </Link>
                </Col> */}
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {final_data_ifrastructure?.map((row, index) => {
                      const isItemSelected = isSelected(row.idactivo);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.idactivo,
                              row.idtipo_activo,
                              row
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.idactivo}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.idactivo}
                          </TableCell>
                          <TableCell align="left">{row.nombre}</TableCell>
                          <TableCell align="left">{row.ip}</TableCell>
                          <TableCell align="left">{row.descripcion}</TableCell>
                          <TableCell align="left">
                            {row.ubicacion_logica}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {row.relevancia}
                          </TableCell>
                          <TableCell align="left">
                            {row.tipo_ambiente}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  //TODO: completar función para encontrar con ID obeto y llenar tabla
  const completarTabla = (obj, id) => {
    let temp = [];

    switch (id) {
      case "procesos":
        setProcesosSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalInfo.filter((dato) => dato.idsubproceso == obj[i])[0]);
        }
        setRowsP(temp);
        setModalShowProcesos(false);
        break;
      case "detalles":
        setComponentesSelected(obj);
        for (let i = 0; i < obj.length; i++) {
          temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
        }
        setRowsC(temp);
        setModalShow(false);
        break;

      default:
        break;
    }
  };
  //Fin del Modal
  let nombreEVC = " ";
  try {
    for (let i = 0; i < evc.length; i++) {
      if (evc[i].idevc == activo.idevc) {
        nombreEVC = evc[i].nombre;
        break;
      }
    }
  } catch { }
  const maplabeldespliegue = selecteddespliegue.map((option) => option.label);
  const labelvaluedesp = maplabeldespliegue.join(",");
  const maplabelcompusada = selectedCompUsada.map((option) => option.label);
  const labelcompusada = maplabelcompusada.join(",");
  const labelDatContenido = datContenidos.join(",");
  const mapcompnusada = companiaDat.map((option) => ({
    value: option.idcompania,
    label: option.compania,
    pais: option.pais,
  }));
  const maptipodespliegue = DataDespliegue.map((option) => ({
    value: option.idparametrosgenerales,
    label: option.vlrmetrica,
  }));
  const maptipocanal = DataCanal.map((option) => ({
    value: option.idparametrosgenerales,
    label: option.vlrmetrica,
  }));
  const maptipoactivo = tipoComp.map((option) => ({
    value: option.idtipo_activo,
    label: option.tipo_activo,
  }));
  const mapcompania = companiaDat.map((option) => ({
    value: option.idcompania,
    label: option.compania,
  }));

  const [alcance, setAlcance] = useState(0);

  const [selectedInfra, setSelectedInfra] = useState([]);

  const isSelectedInfra = (name) => selectedInfra.indexOf(name) !== -1;

  const handleClickInfra = (event, name) => {
    const selectedIndex = selectedInfra.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedInfra(newSelected);
  };

  const handleEditarInfra = () => {
    const idactivo = selectedInfra[0];
    window.open(`/EditarComponenteInfra?idactivo=${idactivo}`);
  };

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />

      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <MyVerticallyCenteredModalProcesos
        procesos={procesosSelected}
        show={modalShowProcesos}
        onHide={() => setModalShowProcesos(false)}
      />

      <Row>
        <Col>
          {" "}
          <h2 className="subtitulo">Editar Componente</h2>
        </Col>
      </Row>
      <hr />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Id Componente</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              disabled
              className="form-control text-center font-weight-bold"
              defaultValue={activo.idactivo}
              id="IDcomponente"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Tipo Componente*</label>
          </Col>
          <Col sm={8} xs={12}>
            <MySelect
              placeholder={"Seleccione tipo de componente"}
              onChange={handleChangeCompT}
              required
              id="componenteT"
              options={maptipoactivo}
              styles={stylesSelect}
              defaultValue={codigoActivo}
              isDisabled={oldIdTipoActivo}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Tipo Canal*</label>
          </Col>
          <Col sm={8} xs={12}>
            <MySelect
              placeholder={"Seleccione tipo de canal"}
              required
              id="canalT"
              options={maptipocanal}
              className="texto"
              styles={stylesSelect}
              defaultValue={selectedCanal}
              onChange={handleChangeCanal}
              isDisabled={tipoCanal !== "Sin Clasificar"}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un tipo de canal.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Tipo Despliegue*</label>
          </Col>
          <Col sm={8} xs={12}>
            <MySelect
              isMulti
              style={{ width: "100%" }}
              className="texto"
              styles={stylesSelect}
              placeholder="Seleccione tipo de despliegue"
              onChange={handleChangedesp}
              options={maptipodespliegue}
              defaultValue={selecteddespliegue}
              id="despliegueT"
              isDisabled={oldIdTipoActivo}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un tipo de despliegue.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del componente"
              defaultValue={activo.nombre}
              required
              id="NombreComponente"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre CMDB</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del componente"
              defaultValue={activo.nombrecmdb}
              disabled
              id="NombreCMDB"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Código App</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Código de la aplicación"
              defaultValue={activo.elemento_configuracion}
              disabled={activo.elemento_configuracion !== null ? true : false}
              //required
              id="CodigoApp"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un código app.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Clase App</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Clase App"
              defaultValue={activo.clase_app}
              disabled
              required
              id="CodigoApp"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nube de despliegue</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nube de Despliegue"
              defaultValue={activo.nube == 0 ? "" : activo.nube}
              disabled
              id="Nube"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <label className="label">¿Es BIA?</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              // placeholder="Código de la aplicación"
              defaultValue={activo.bia ? "Sí" : "No"}
              disabled
              id="BIA"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <label className="label">¿Es SOX?</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              // placeholder="Código de la aplicación"
              defaultValue={activo.sox ? "Sí" : "No"}
              disabled
              // required
              id="SOX"
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción</label>
          </Col>
          <Col sm={8} xs={10}>
            <textarea
              className="form-control text-center"
              placeholder="Descripción del componente"
              defaultValue={activo.descripcion}
              rows="3"
              disabled
              id="Descripcion"
            ></textarea>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">
              Datos contenidos en el dominio
            </label>
          </Col>
          <Col sm={8} xs={10}>
            <div className="input-tag">
              <ul className="input-tag__tags">
                {datContenidos.map((tag, i) => (
                  <li key={tag}>
                    {tag}
                    <button
                      type="button"
                      onClick={() => {
                        removeTag(i);
                      }}
                    >
                      +
                    </button>
                  </li>
                ))}
                <li className="input-tag__tags__input">
                  <input
                    placeholder="Datos separados..."
                    id="datosSeparados"
                    type="text"
                    onKeyDown={(e) => {
                      inputKeyDown(e);
                    }}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Compañía Principal*</label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              placeholder={"Seleccione la compañía..."}
              options={mapcompania}
              onChange={handleChange}
              styles={stylesSelect}
              defaultValue={selectedValueCompania}
              isDisabled={true}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione una compañía.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label forn-label">Compañías que la usan*</label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              isMulti
              className="texto"
              styles={stylesSelect}
              style={{ width: "100%" }}
              placeholder="Seleccione compañías que la usan..."
              onChange={handleChangeCompUsada}
              options={mapcompnusada}
              id="companiausada"
              defaultValue={selectedCompUsada}
            />
          </Col>
        </Row>
        {alcance == 1 ? (
          <Row className="mb">
            <Col className="text-center">
              <p className="label forn-label" style={{ color: "red" }}>
                Este componente es corporativo, recuerda que los riesgos de este
                componente deben ser validados con los otros países impactados
              </p>
            </Col>
          </Row>
        ) : null}
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">
              Responsable en tecnología*
            </label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              className="texto"
              onChange={handleChangeTI}
              options={usuariosDatTI}
              defaultValue={selectedValueResponsableTI}
            />
            <Form.Control.Feedback type="invalid">
              Por favor seleccione un responsable.
            </Form.Control.Feedback>
          </Col>
          <Col sm={2} xs={12}>
            <label className="label forn-label">Responsable del negocio</label>
          </Col>
          <Col sm={3} xs={10}>
            <MySelect
              placeholder={"Seleccione el responsable..."}
              className="texto"
              onChange={handleChangeNeg}
              options={usuariosDatNeg}
              defaultValue={selectedValueResponsableNeg}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label text-center">EVC*</label>
          </Col>
          <Col sm={3} xs={10}>
            <Select
              required
              id="EVC"
              placeholder={"Seleccione EVC"}
              options={evc.map((evcAso) => ({
                value: evcAso.idevc,
                label: evcAso.nombre,
              }))}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={0} />
          <Col>
            <div className="texto form-text">* Campos obligatorios</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={1}></Col>
          <Col sm={3} xs={3}>
            {" "}
            <Button type="submit" className="botonPositivo" id="send">
              Guardar
            </Button>
          </Col>
          <Col sm={3} xs={3}>
            {" "}
            <Link to="componente-tecnologico">
              <Button className="botonNegativo">Descartar</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>
        <ModalCarga show={showModal} onHide={() => setShowModal(false)} />
        {ismodule === 1 ? (
        <>
          <Row className="mb-3">
            <Col md={6}>
              <h2 className="subtitulo">Módulos asociados</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <TableContainer component={Paper} className="table table-bordered">
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Dirección IP</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Descripción</StyledTableCell>
                    <StyledTableCell align="left">
                      Ubicación Logica
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Tipo de Ambiente
                    </StyledTableCell>
                    <StyledTableCell align="left">Relevancia</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsM !== null
                    ? rowsM.map((row, index) => {
                        //const isItemSelected = isSelectedInfra(row.idactivo);
                        return (
                          <StyledTableRow
                            key={row.idactivo}
                            hover
                            // onClick={(event) =>
                            //   handleClickInfra(event, row.idactivo)
                            // }
                            // selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.idactivo}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.ip}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.nombre}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.descripcion}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.ubicacion_logica}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.tipo_ambiente}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.relevancia}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        </>
        ) : 
        <>
          <Row className="mb-3">
            <Col md={6}>
              <h2 className="subtitulo">Componente principal</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={2} xs={12}>
              <label className="label forn-label">ID</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                type="text"
                className="form-control text-center"
                placeholder="ID del activo"
                defaultValue={dataComponentePrincipalId}
                disabled
                id="IDActivo"
              ></input>
            </Col>
            <Col sm={2} xs={12}>
              <label className="label forn-label">Nombre</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                type="text"
                className="form-control text-center"
                placeholder="Nombre del activo"
                defaultValue={dataComponentePrincipalNombre}
                disabled
                id="NombreActivo"
              ></input>
            </Col>
          </Row>
        </>
        }
        <hr />
        <Row className="mb-3">
          <Col md={6}>
            <h2 className="subtitulo">Añadir Componentes Infraestructura</h2>
          </Col>
          <Col md={2}>
            <Button
              className="botonDescargar"
              onClick={() => setShowModal(true)}
            >
              Cargue Masivo de Componentes
            </Button>
          </Col>
          {selectedInfra[0] ? (
            <Col md={2}>
              <Button className="botonDescargar" onClick={handleEditarInfra}>
                Editar
              </Button>{" "}
            </Col>
          ) : (
            <Col md={2}>
              <Link to="NuevoComponenteInfra" target="_blank">
                <Button className="botonIngreso">Crear</Button>{" "}
              </Link>
            </Col>
          )}
          <Col md={2}>
            <Button className="botonIngreso" onClick={() => setModalShow(true)}>
              Añadir
            </Button>{" "}
          </Col>
        </Row>
        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Dirección IP</StyledTableCell>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Descripción</StyledTableCell>
                  <StyledTableCell align="left">
                    Ubicación Logica
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Tipo de Ambiente
                  </StyledTableCell>
                  <StyledTableCell align="left">Relevancia</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsc !== null
                  ? rowsc.map((row, index) => {
                    const isItemSelected = isSelectedInfra(row.idactivo);
                    return (
                      <StyledTableRow
                        key={row.idactivo}
                        hover
                        onClick={(event) =>
                          handleClickInfra(event, row.idactivo)
                        }
                        selected={isItemSelected}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={isItemSelected} />
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.idactivo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.ip}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.nombre}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.descripcion}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.ubicacion_logica}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.tipo_ambiente}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.relevancia}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col md={10}>
            <h2 className="subtitulo">Procesos asociados al componente ​</h2>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => setModalShowProcesos(true)}
            >
              Añadir
            </Button>{" "}
          </Col>
        </Row>

        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID Proceso</StyledTableCell>
                  <StyledTableCell align="left">
                    Nombre del Proceso
                  </StyledTableCell>
                  <StyledTableCell align="left">Macroproceso</StyledTableCell>
                  <StyledTableCell align="left">Subproceso</StyledTableCell>
                  {/* <StyledTableCell align="left">Estado del Proceso</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsP !== null && rowsP !== undefined
                  ? rowsP.map((row, index) =>
                    row.estadoasociacion !== false ? (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.idsubproceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.proceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.macroproceso}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.subproceso}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left"><Checkbox
                                            value="checkedA"
                                            inputProps={{ 'aria-label': 'Checkbox A' }}
                                            checked={row.estado}/>
            </StyledTableCell> */}
                      </StyledTableRow>
                    ) : null
                  )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>
        <hr />
        <Row className="mb-3">
        </Row>
        {Array.isArray(datagrafo.nodes) && datagrafo.nodes.length > 0 ? (
          <>
            <Row>
              <Col md={10}>
                <h2 className="subtitulo">Relación del aplicativo</h2>
              </Col>
            </Row>
            <Row className="grafo" style = {{backgroundColor:'#F2F2F2'}}>
              <DynamicGraph data={datagrafo} />
            </Row>
          </>
        ) : (
          <Row className="grafo">
            <Col md={2}>
              <a>{" "}</a>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default EditarComponente;
