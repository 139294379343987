import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import AADService from "../funcionesAuth.js";
import * as echarts from "echarts";
const PieChartComponent = () => {
  const [selectedOption, setSelectedOption] = useState("total");
  //const AzureADService = new AADService();
  //const token = AzureADService.getToken();
  useEffect(() => {
    const chartDom = document.getElementById("chart");
    const myChart = echarts.init(chartDom);
    // Definir los datos para cada opción
    const dataOptions = {
      total: [
        { value: 2476, name: "Business Application" },
        { value: 180, name: "Security Application" },
        { value: 62, name: "Software Telco" },
        { value: 56, name: "Software Plat. de Aplicaciones" },
        { value: 214, name: "Otras" },
      ],
      esquemas: [
        { value: 1143, name: "Iaas" },
        { value: 861, name: "On Premises" },
        { value: 351, name: "Nube Privada" },
        { value: 161, name: "Saas" },
        { value: 115, name: "Paas" },
      ],
      nubes: [
        { value: 1472, name: "AWS" },
        { value: 99, name: "IBM Cloud Private" },
        { value: 59, name: "Azure" },
        { value: 42, name: "Otras" },
      ],
      principalesmodulos: [
        { value: 2294, name: "Aplicaciones Principales" },
        { value: 694, name: "Modulos" },
      ],
      bia: [
        { value: 212, name: "SI" },
        { value: 2747, name: "NO" },
      ],
      sox: [
        { value: 111, name: "SI" },
        { value: 2846, name: "NO" },
      ],
    };
    // Configuración del gráfico
    const option = {
      legend: {
        top: "top",
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "pie",
          radius: "50%",
          center: ["50%", "50%"],
          data: dataOptions[selectedOption], // Datos dinámicos basados en la selección
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            formatter: "{b}: {c} ({d}%)",
          },
        },
      ],
    };
    myChart.setOption(option);
    return () => {
      myChart.dispose();
    };
  }, [selectedOption]); // El gráfico se actualiza cuando cambia la opción seleccionada
  return (
    <div>
      <select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        style={{ marginBottom: "20px" }}
      >
        <option value="total">Total</option>
        <option value="principalesmodulos">Principales-Modulos</option>
        <option value="esquemas">Esquemas</option>
        <option value="nubes">Nubes</option>
        <option value="bia">BIA</option>
        <option value="sox">SOX</option>
      </select>
      <div id="chart" style={{ width: "600px", height: "400px" }}></div>
    </div>
  );
};
const ResumenComponentes = () => {
  const initialData = [
    {
      clase: "Business Application",
      principales: 1844,
      modulos: 632,
      total: 2476,
    },
    {
      clase: "Security Application",
      principales: 157,
      modulos: 23,
      total: 180,
    },
    {
      clase: "Software Telco",
      principales: 61,
      modulos: 1,
      total: 21,
    },
    {
      clase: "Software Plat. de Aplicaciones",
      principales: 29,
      modulos: 27,
      total: 56,
    },
    {
      clase: "Otras",
      principales: 203,
      modulos: 11,
      total: 214,
    },
  ];
  const [activo, setActivo] = useState(initialData);
  return (
    <div>
      <br />
      <br />
      <Row>
        <Col sm={6} xs={12}>
          <TableContainer>
            <Table stickyHeader size={"medium"} maxHeightBody="55vh">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Clase
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Principales
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Modulos
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activo.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.clase}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.principales}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.modulos}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.total}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <p>Conteos actualizados hasta 4 de septiembre del 2024</p>
        </Col>
        <Col sm={6} xs={12}>
          <PieChartComponent />
        </Col>
      </Row>
    </div>
  );
};
export default ResumenComponentes;