import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ResumenComponentes from './ResumenComponentes';
import Mtable_Componentes from './Mtable_Componentes';

const MainComponente = () => {
    const idCompania = localStorage.getItem("idcompania");
    const [value, setValue] = useState(idCompania === "1" ? 0 : 1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    {idCompania === "1" ? (
                        <Tab label="Resumen" style={{ color: "#ffffff" , padding: "3px 7px 3px"}}  />
                    ) : (
                        <Tab label="Resumen" style={{ color: "#ffffff", padding: "3px 7px 3px" }} disabled />
                    )}
                    <Tab label="Aplicaciones" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Módulos" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                </Tabs>
            </AppBar>
            {value === 0 && idCompania === "1" && <ResumenComponentes />}
            {value === 1 && <Mtable_Componentes value={1} />}
            {value === 2 && <Mtable_Componentes value={2} />}
        </div>
    );
};

export default MainComponente;
