import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { param } from "jquery";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TablePagination from "@mui/material/TablePagination";
import { el } from "date-fns/locale";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { IoMdDownload, IoMdAttach  } from "react-icons/io";
import XLSX from "xlsx";
import { ContactSupportOutlined } from "@material-ui/icons";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function AlertEfectividadControles({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant='success'>
          Cálculo de efectividades realizado exitosamente
        </Alert>
      );
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    case 7:
      return (
        <Alert variant='warning'>
          No hay vulnerabilidades asociadas a la evaluacion
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//Se definen las Columnas que tendra la tabla
const ControlesEvaCells = [

  {
    id: "idImplementador",
    numeric: false,
    disablePadding: false,
    label: "Id Control",
  },
  {
    id: "implementador",
    numeric: false,
    disablePadding: false,
    label: "Control",
  },
  {
    id: "descripcion",
    numeric: false,
    disablePadding: false,
    label: "Descripción del Control",
  },
  {
    id: "aplicabilidad",
    numeric: false,
    disablePadding: false,
    label: "Aplica",
  },
  {
    id: "implementado",
    numeric: false,
    disablePadding: false,
    label: "Implementado",
  },
  {
    id: "efectividad",
    numeric: false,
    disablePadding: false,
    label: "Diseño del Control % ",
    
  },
  {
    id: "Automatizacion",
    numeric: false,
    disablePadding: false,
    label: "Automatización",
    
  },
  {
    id: "Naturaleza",
    numeric: false,
    disablePadding: false,
    label: "Naturaleza",
    
  },
];

/*Encabezado de tabla*/
function EnhancedTableHead(props) {
  const columnWidths = {
    idimplementador: '10%',
    implementador: '20%',
    descripcion: '30%',
    aplicabilidad: '10%',
    automatizacion: '10%',
    efectividad: '20%', // Aquí se define un ancho específico para las columnas
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        ></TableCell>
        {ControlesEvaCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{
              backgroundColor: "#2c2a29",
              color: "#ffffff",
              width: columnWidths[headCell.id]
            }}
          >
            <label className='label'>{headCell.label}</label>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

//Estilo de la Tabla
const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "70vh",
    minHeight: "70vh",
    overflowY: "auto",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "fit-content",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

//Funcion que retorna la Tabla completa
export default function ControlesEvaNew({ shouldExecuteEffect}) {
  //Constantes para el funcionamiento de la Tabla
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [loadingEfect, setLoadingEfect] = React.useState(false);
  const [datosControles, setDatosControles] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [dataCarga, setDataCarga] = React.useState([]);
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //Constantes para consumir datos de los Controles
  const [disabledimp, setDisabledimp] = React.useState(false);
  const [tecnologia, setTecnologia] = React.useState([]); //contiene la data para la formula de diseño
  const [messageNews, setMessageNews] = React.useState(false)
  const [estadoPUT, setEstadoPUT] = React.useState(0);
  const [estadoPUTEfectividad, setEstadoPUTEfectividad] = React.useState(0);
  const [automatizacionvln, setAutomatizacionVln] = React.useState([]);
  //const [gestionvln, setGestionVln] = React.useState([]);
  const [implexEval, setImplexEval] = React.useState([]);
  const [implexRiesgo, setImplexRiesgo] = React.useState([]);
  const [riesgoxamenaza, setRiesgoxamenaza] = React.useState([]);
  const [queryText, setqueryText] = React.useState("");
  //const [cantidadComps, setCantidadComps] = React.useState(1);
  const [filterValue, setFilterValue] = React.useState([]);
  const [analizarEfectividades, setAnalizarEfectividades] =
    React.useState(false);
  var codigosResueltos = [];

  const [validRole, setValidRole] = React.useState(
    localStorage.getItem("rolusuario") == 2 ||
      localStorage.getItem("rolusuario") == 1
      ? true
      : false
  );
  
  const mapeLetrasInicialesDespliegue = (dataDespliegue) => {
    const palabras = dataDespliegue.split(",");
    const iniciales = palabras.map((palabra) => palabra[0]);
    return iniciales.join('');
  };
  const [newRuta, setNewRuta] = React.useState("");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  useEffect(() => {
    if (shouldExecuteEffect) {
      const dataStorageDespliegue = localStorage.getItem("variable_tipo_despliegue");
      const dataStorageTipoCanal = localStorage.getItem("variable_tipo_canal");
      const dataStorageComponente = localStorage.getItem("variable_tipo_activo");
      const inicialesDespl = mapeLetrasInicialesDespliegue(dataStorageDespliegue);
      const rutaGetTiposNew = dataStorageComponente + "/" + inicialesDespl + "/" + dataStorageTipoCanal + "/";
      const fetchdataControles = async () => {
      setLoading(true);
      const result = await fetch(
          process.env.REACT_APP_API_URL +
          "evaluacion/controlesNew/" +
          localStorage.getItem("idcompania") +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/" +
          rutaGetTiposNew,
          {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
          },
          }
      );
      if (result.status === 200) {
          let param = await result.json();
          setTecnologia(param['dict_controles']);
          setNewRuta(rutaGetTiposNew);
          setLoading(false);
          if (param['nuevos'] === true)
            {
              setMessageNews(true)
            }
      } else {
          return null;
      }
      };
      const automatizacionFetch = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "general/parametrosGeneralesxGrupoxParametro/Controles/Automatizacion_control/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let param = await result.json();
        setAutomatizacionVln(param);
      };
      const fetchdataControlImplexEval = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              'control/implementadorxeval/' +
              localStorage.getItem('idevaluacion') +
              '/',
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setImplexEval(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlImplexEval:', error);
          setImplexEval(null);
        }
      };
      const fetchdataControlImplementadorxRiesgo = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "control/implementadorxriesgo/" +
              localStorage.getItem("idevaluacion") +
              "/",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setImplexRiesgo(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlImplementadorxRiesgo:', error);
          setImplexRiesgo(null);
        }
      };
      const fetchdataControlRiesgoxamenaza = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "control/riesgoxamenaza/" +
              localStorage.getItem("idevaluacion") +
              "/",
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setRiesgoxamenaza(param);
        } catch (error) {
          console.error('ERROR en fetchdataControlRiesgoxamenaza:', error);
          setRiesgoxamenaza(null);
        }
      };
      fetchdataControlImplexEval();
      fetchdataControlImplementadorxRiesgo();
      fetchdataControlRiesgoxamenaza();
      //gestionFetch();
      fetchdataControles();
      automatizacionFetch();
    }
  }, [shouldExecuteEffect]);

  function handleControlChange(index, controlData) {
    setTecnologia(prevTecnologia => {
      const newTecnologia = [...prevTecnologia];
      newTecnologia[index] = { ...newTecnologia[index], ...controlData };
      return newTecnologia;
    });
  }

  function ExportarExcel(data_controles){
    const datosParaExportar = data_controles.map((json) => {
      return {
        idcontrol_evaluacion: json["idcontrol_evaluacion"],
        idimplementador: json["idimplementador"],
        nombre: json["nombre"],
        descripcion: json["descripcion"],
        aplicabilidad: json["aplicabilidad"] ? json["aplicabilidad"] === "Aplica" ? "Si" : "No" : "Si",
        implementado: json["implementado"] ? json["implementado"] === "Si" ? "Si" : "No" : "No",
        efectividad: json["efectividad"],
        automatizacion: json["nivel_automatizacion"],
        naturaleza: json["tipo_control"],
      };
    });
    const newBook = XLSX.utils.book_new();
    const sheet1 = XLSX.utils.json_to_sheet(datosParaExportar);
    sheet1['!cols'] = [];
    sheet1['!cols'][0] = { hidden: true };
    sheet1['!cols'][1] = { hidden: true };
    if(!sheet1.E1.c) sheet1.E1.c = [];
    if(!sheet1.F1.c) sheet1.F1.c = [];
    sheet1.E1.c.push({t:"Digitar únicamente Si o No"});
    sheet1.F1.c.push({t:"Digitar únicamente Si o No"});
    XLSX.utils.book_append_sheet(newBook, sheet1, "Controles");
    XLSX.writeFile(newBook,"Edición_Controles_" + "idevaluacion_" + localStorage.getItem("idevaluacion") + ".xlsx");
  }

  const handleButtonClick = () =>{
    setShowModal(true);
  };

 const handleCloseModal = () => {
  setShowModal(false);
  };

 const elegirArchivo = async (e) => {
  const file = e.target.files[0];
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const jsondata = XLSX.utils.sheet_to_json(worksheet);
  setDataCarga(jsondata);
}

function cargarArchivo() {
  setLoading(true);
  let datos_carga = dataCarga;
  if (datos_carga.length === 0) {
    alert("No se ha seleccionado un archivo")
    setLoading(false);
    return
  }
  let array_response = [];
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  for (let i = 0; i < datos_carga.length; i++) {
    let aplicabilidad = datos_carga[i].aplicabilidad
    let implementado = datos_carga[i].implementado

    if (aplicabilidad == "Si" || aplicabilidad == "SI") {
      aplicabilidad = "Si";
    }
    else {
      aplicabilidad = "No";
    }

    if (implementado == "Si" || implementado == "SI") {
      implementado = "Si";
    }
    else {
      implementado = "No";
    }

    if (aplicabilidad == "No") {
      implementado = "No";
    }

    array_response.push({
      idcontrol_evaluacion: datos_carga[i].idcontrol_evaluacion,
      idevaluacion: localStorage.getItem("idevaluacion"),
      idimplementador: datos_carga[i].idimplementador,
      aplicabilidad: aplicabilidad === "Si" ? "Aplica" : "No aplica",
      implementado: implementado,
    });
  }
  //Actualizar a tecnologia por medio de setTecnologia
  setTecnologia(tecnologia.map(item => {
    const found = array_response.find(arItem => arItem.idimplementador === item.idimplementador);
    if (found) {
      return {
        ...item,
        aplicabilidad: found.aplicabilidad,
        implementado: found.implementado,
      };
    }
    return item;
  }));
  setShowModal(false);
  setLoading(false);
  alert("Recuerde guardar los cambios")
  }

  const sendData = (event) => {
    event.preventDefault();
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    const dataGeneral = tecnologia.map((control, index) => {
      return {
        idcontrol_evaluacion: control.idcontrol_evaluacion ? control.idcontrol_evaluacion : null,
        idevaluacion: localStorage.getItem("idevaluacion") ? localStorage.getItem("idevaluacion") : null,
        idcontrol: control.idcontrol ? control.idcontrol : null,
        idimplementador: control.idimplementador,
        aplicabilidad: control.aplicabilidad ? control.aplicabilidad === "Si" || control.aplicabilidad === "Aplica" ? "Aplica" : "No Aplica" : "Aplica",
        nivel_automatizacion: control.nivel_automatizacion,
        eficaciaoperativa: control.eficaciaoperativa,
        pruebatecnica: control.pruebatecnica,
        efectividad:  parseFloat(parseFloat(document.getElementById("efectividad" + index.toString()).innerText).toFixed(4) / 100).toFixed(5),
        implementado: control.implementado ? control.implementado === "Si" ? "Si" : "No" : "No",
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechacreacion: today,
        nuevo: 1,
      };
    });
    
    setLoading(true);

    let datos = JSON.stringify(dataGeneral);
    fetch(
      process.env.REACT_APP_API_URL +
        "evaluacion/controlesNew/" +
        localStorage.getItem("idcompania") +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/" +
        newRuta,
      {
        method: "PUT",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((data) =>
    data.json().then((response) => {
      if (data.status >= 200 && data.status < 300) {
        const fetchdataRefresh = async () => {
          const result = await fetch(
            process.env.REACT_APP_API_URL +
              "evaluacion/controlesNew/" +
              localStorage.getItem("idcompania") +
              "/" +
              localStorage.getItem("idevaluacion") +
              "/" +
              newRuta,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          let param = await result.json();
          setEstadoPUT(2);
          setTecnologia(param['dict_controles']);
          if (param['nuevos'] === true)
            {
              setMessageNews(true)
            }
          setAnalizarEfectividades(true);
          setLoading(false);
        };
        fetchdataRefresh();
      } else if (data.status >= 500) {
        setEstadoPUT(5);
        setLoading(false);
      } else if (data.status >= 400 && data.status < 500) {
        setEstadoPUT(4);
        setLoading(false);
      }
    })
  )
  .catch(function (err) {
    console.log(err);
  });

}
  const calcularEfectividades = () => {
    setLoadingEfect(true);
    let data = {
      idusuariomodificacion: localStorage.getItem("idusuario"),
      idevaluacion: localStorage.getItem("idevaluacion"),
    };
    let datos = JSON.stringify(data);
    fetch(
      process.env.REACT_APP_API_URL + "evaluacion/controlesNew/efectividades/",
      {
        method: "PUT",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((data) =>
        data.json().then((response) => {
          if (data.status >= 200 && data.status < 300) {
            setEstadoPUTEfectividad(2);
            setLoadingEfect(false);
          } else if (data.status >= 500) {
            setEstadoPUTEfectividad(5);
            setLoadingEfect(false);
          } else if (data.status === 404) {
            setEstadoPUTEfectividad(7);
            setLoadingEfect(false);  
          } else if (data.status >= 400 && data.status < 500) {
            setEstadoPUTEfectividad(4);
            setLoadingEfect(false);
          }
        })
      )
      .catch(function (err) {
        console.log(err);
      });
  };

  function Control(props) {
    const { control, index, arregloAutomatizacion, onControlChange } = props;
    const labelId = `enhanced-table-checkbox-${index}`;
    const [nivelAutomatizacion, setNivelAutomatizacion] = React.useState(
      control.nivel_automatizacion
    );
    const [aplicabilidad, setAplicabilidad] = React.useState(
      control.aplicabilidad ? control.aplicabilidad === "Aplica" ? "Si" : "No" : "Si"
    );
    const [implementado, setImplementado] = React.useState(
      control.implementado ? control.implementado : "No"
    );

    const [eficaciaOperativa, setEficaciaOperativa] = React.useState(
      control.eficaciaoperativa == null ? 0.0 : control.eficaciaoperativa
    );
    const [pruebaTecnica, setPruebaTecnica] = React.useState(
      control.pruebatecnica == null ? 0.0 : control.pruebatecnica
    );
    const [efectividad, setEfectividad] = React.useState(
      control.efectividad ? control.efectividad : 
           calcularEfectividadAutomatico(
              control,
              nivelAutomatizacion,
              pruebaTecnica,
              eficaciaOperativa,
            )
    );
    const arreglo_aplicabilidad = [
      "Si",
      "No",
    ];
    const arreglo_implementado = [
      "Si",
      "No",
    ];

    const list_aplica = arreglo_aplicabilidad.map((aplica) => { 
      if (aplica !== aplicabilidad) {
        return <option value={aplica}>{aplica}</option>;
      } else {
        return (
          <option value={aplica} selected>
            {aplicabilidad}
          </option>
        );
      }
    });

    const list_implementado = arreglo_implementado.map((imple) => {
      if (imple !== implementado) {
        return <option value={imple}>{imple}</option>;
      } else {
        return (
          <option value={imple} selected>
            {implementado}
          </option>
        );
      }
    });

    function handleChangeAplicabilidad(e) {
      let value = e.target.value;
      setAplicabilidad(value);
      onControlChange({ aplicabilidad: value === "Si" ? "Aplica" : "No Aplica" });
      if (value === "No") {
        onControlChange({ implementado: "No" });
      }
    }

    function handleChangeImplementacion(e) {
      let value = e.target.value;
      setImplementado(value);
      onControlChange({ implementado: value });
    }

    function calcularEfectividadAutomatico( //calcula el valor de la formula 
      controlCambio,
      automa,
      prueba,
      eficaciaOp
    ) {
      let naturaleza = parseFloat(controlCambio.pesotipocontrol);
      let automatizacion = 0;
      for (let i = 0; i < arregloAutomatizacion.length; i++) {
        if (automa != null) {
          if (arregloAutomatizacion[i].vlrmetrica === automa) {  //verifica cual fue la automatización y define el valor según BD
            automatizacion = arregloAutomatizacion[i].vlrnumericometrica1;
            break;
          }
        } else {
          if (arregloAutomatizacion[i].vlrmetrica === automa) {
            automatizacion = arregloAutomatizacion[i].vlrnumericometrica1;
            break;
          }
        }
      }
      automatizacion = parseFloat(automatizacion); 
      let performance = parseFloat(eficaciaOp).toFixed(4);
      let vulnerabilidad = parseFloat(prueba).toFixed(4);      
      //De forma temporal pondremos vulnerabilidad = 1
      vulnerabilidad = 1;

      /*if (isNaN(vulnerabilidad) || vulnerabilidad <= 0.0) {
        vulnerabilidad = 1;
      } */
      if (performance < 0.95  || performance === "" || isNaN(performance) && naturaleza === 10) {
        naturaleza = 9
      }
      if (performance < 0.95  || performance === "" || isNaN(performance) && automatizacion === 10) {
        automatizacion = 9
      }
      let resultado = (((naturaleza + automatizacion)/2) * 1 * vulnerabilidad)*10;
      if (resultado > 99.9) {
        resultado = 99.9;
        resultado = resultado.toFixed(4);
        return resultado;
      }
      resultado = resultado.toFixed(4);
      return resultado;
    }

    return (
      <TableRow
        hover
        role='checkbox'
        tabIndex={-1}
        key={control.idimplementadorporcontrol}
      >
        <TableCell padding='checkbox'></TableCell>
        <TableCell align='left'>{control.idimplementador}</TableCell>
        <TableCell align='left'>{control.nombre}</TableCell>
        <TableCell align='left'>{control.descripcion}</TableCell>
        <TableCell align='center'>
          <select
            className='form-control aplicabilidad'
            id={"aplicabilidad" + index.toString()}
            onChange={handleChangeAplicabilidad}
            value={aplicabilidad}
          >
            {list_aplica}
          </select>
        </TableCell> 
        <TableCell align='center'>
          <select
            className='form-control automatizacion'
            id={"implementado" + index.toString()}
            onChange={handleChangeImplementacion}
            value={implementado}
            disabled={aplicabilidad === "No" ? true : false}
          >
            {list_implementado}
          </select>
        </TableCell>
        <TableCell align='center' id={"efectividad" + index.toString()}>
          {parseFloat(efectividad).toFixed(4)}
        </TableCell> 
        <TableCell align='left'>{control.nivel_automatizacion}</TableCell>
        <TableCell align='left'>{control.tipo_control}</TableCell>
      </TableRow>
    );
  }
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(implexEval),
      sheet2 = XLSX.utils.json_to_sheet(implexRiesgo),
      sheet3 = XLSX.utils.json_to_sheet(riesgoxamenaza);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Ctroles_Implem_Evaluacion");
    XLSX.utils.book_append_sheet(newBook, sheet2, "ImplementadorxRiesgo");
    XLSX.utils.book_append_sheet(newBook, sheet3, "ImplxRiesgoxAmz");
    XLSX.writeFile(newBook, "Informes de Controles.xlsx");
  };
  const evaluationState = JSON.parse(localStorage.getItem("estado_evaluacion"));
  return (
    <>
      <AlertDismissibleExample alerta={estadoPUT} />
      <AlertEfectividadControles alerta={estadoPUTEfectividad} />
      <Form id='formData' onSubmit={(e) => sendData(e)}>
        <div className={classes.root}>
        {messageNews && (
            <Row>
              <Col>
                <p style={{ color: "red" }}>
                  Por caracterización de controles es posible que tengas controles no calificados
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={9}>
              <h2 className='subtitulo'>Listado de Controles</h2>
            </Col>
          </Row>
          <hr />
          <Row>
            <Modal show={showModal} onHide={handleCloseModal}>
              <Form></Form>
            <Modal.Header closeButton>
              <Modal.Title>Selecciona un archivo</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <input 
            className="Archivo" 
            id="inputCargar"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            name="files"
            onChange={(e) => elegirArchivo(e)}
            />
            </Modal.Body>

            <Modal.Footer>
            <Button
            type='submit'
            className='botonCargar2'
            style={{ minWidth: "20%" }}
            onClick={cargarArchivo}>
              Cargar
            </Button>
              <Button className='botonCancelar' variant="secondary" 
              onClick={handleCloseModal}>
                Cancelar
              </Button>
            </Modal.Footer>
            </Modal>
          </Row>

          <Row className='mb-2' style={{ justifyContent: "end" }}>
            <Col sm={1}>{loadingEfect ? <CircularProgress /> : ""}</Col>
            <Col sm={2}>
              {analizarEfectividades ? (
                <Button
                  className='botonIngreso2'
                  onClick={() => calcularEfectividades()}
                >
                  Calcular Efectividades
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col sm={2}>
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                  className='botonDescargar'
                  id='descargar_cuestionario'
                  style= {{
                    minWidth: "215px",
                    minHeight: "46px",
                  }}
                  onClick = {() => ExportarExcel(tecnologia)}>
                  Descargar Controles  &nbsp;
                  <IoMdDownload />
                </Button>
                )}
              </>
            </Col>
            <Col sm={2}>
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                  className='botonCargar'
                  id='descargar_cuestionario'
                  style= {{
                    minWidth: "215px",
                    minHeight: "46px",
                  }}
                  onClick = {handleButtonClick}>
                  Cargar Controles  &nbsp;
                  <IoMdAttach />
                </Button>
                )}
              </>
            </Col>
            <Col sm={2}>
              {validRole && evaluationState ? (
                <Button
                  className='botonPositivo'
                  onClick={() => setLoading(true)}
                  type='submit'
                >
                  Guardar
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <hr />
          <Paper >
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby='tableTitle'
                size='medium'
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {tecnologia.map(
                    (row, index) => {
                      return (
                        <Control
                          control={row}
                          index={index}
                          arregloAutomatizacion={automatizacionvln}
                          onControlChange={(controlData) => handleControlChange(index, controlData)}
                        ></Control>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Form>
    </>
  );
}
